import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Drawer, IconButton } from '@mui/material';

import Logo from './Logo';
import {
  AccountIcon,
  DashboardIcon,
  DeviceCatalogueIcon,
  DeviceOrderIcon,
  DeviceRentalIcon,
  FeatureControllerIcon,
  PlanIcon,
  RoleIcon,
  SubscriptionIcon,
  TransactionIcon,
  UserIcon,
  CloseIcon,
} from './svgIcon';
import { checkPermissionAllow } from '../../utils/helpers';

function SideBar(props) {
  const { drawerOpen, setDrawerOpen } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locate = useLocation();
  const currentPath = locate.pathname;
  const parts = currentPath.split('/');
  const slicedString = parts.slice(0, 4).join('/');
  const sidebar = [
    {
      name: t('home'),
      children: [
        {
          name: t('dashboard'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <DashboardIcon />,
          url: '/super-admin/dashboard',
          active: false,
          key: 'dashboard',
          enable: checkPermissionAllow('viewDashboard'),
        },
      ],
      enable: checkPermissionAllow('viewDashboard'),
    },
    {
      name: t('accountManagement'),
      children: [
        {
          name: t('cmsCloud'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <AccountIcon />,
          url: '/super-admin/company/direct',
          active: false,
          key: 'company',
          enable: checkPermissionAllow('getCompany'),
        },
        {
          name: t('cmsServer'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <AccountIcon />,
          url: '/super-admin/company/cmsServer/direct',
          active: false,
          key: 'company',
          enable: checkPermissionAllow('getServerCompany'),
        },
        {
          name: t('distributor'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <AccountIcon />,
          url: '/super-admin/company/distributor',
          active: false,
          key: 'company',
          enable: checkPermissionAllow('getDistributors'),
        },
      ],
      enable: checkPermissionAllow('getCompany') || checkPermissionAllow('getDistributors'),
    },
    {
      name: t('userManagement'),
      children: [
        {
          name: t('roles'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <RoleIcon />,
          url: '/super-admin/roles',
          active: false,
          key: 'roles',
          enable: checkPermissionAllow('getRole'),
        },
        {
          name: t('users'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <UserIcon />,
          url: '/super-admin/users',
          active: false,
          key: 'users',
          enable: checkPermissionAllow('getUsers'),
        },
      ],
      enable: checkPermissionAllow('getRole') || checkPermissionAllow('getUsers'),
    },
    {
      name: t('subscriptionManagement'),
      children: [
        {
          name: t('subscriptions'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <SubscriptionIcon />,
          url: '/super-admin/subscription/active',
          active: false,
          key: 'roles',
          enable: checkPermissionAllow('getSubscription'),
        },
        {
          name: t('plans'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <PlanIcon />,
          url: '/super-admin/plans',
          active: false,
          key: 'plans',
          enable: checkPermissionAllow('getPlan'),
        },
        {
          name: t('transactions'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <TransactionIcon />,
          url: '/super-admin/transaction/summary',
          active: false,
          key: 'transaction',
          enable: checkPermissionAllow('getTransaction'),
        },
        {
          name: t('addons'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <PlanIcon />,
          url: '/super-admin/addons',
          active: false,
          key: 'plans',
          enable: checkPermissionAllow('getAddons'),
        },
      ],
      enable: checkPermissionAllow('getSubscription')
        || checkPermissionAllow('getPlan')
        || checkPermissionAllow('getTransaction')
        || checkPermissionAllow('getAddons'),
    },
    {
      name: t('deviceManagement'),
      children: [
        {
          name: t('deviceOrder'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <DeviceOrderIcon />,
          url: '/super-admin/device-order/processed',
          active: false,
          key: 'catalogue',
          enable: checkPermissionAllow('getDeviceOrder'),
        },
        {
          name: t('deviceRental'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <DeviceRentalIcon />,
          url: '/super-admin/device-rental/active',
          active: false,
          key: 'catalogue',
          enable: checkPermissionAllow('getDeviceRental'),
        },
        {
          name: t('deviceCatalogue'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <DeviceCatalogueIcon />,
          url: '/super-admin/device-catalogue',
          active: false,
          key: 'catalogue',
          enable: checkPermissionAllow('getDeviceCatalogue'),
        },
      ],
      enable: checkPermissionAllow('getDeviceOrder')
        || checkPermissionAllow('getDeviceRental')
        || checkPermissionAllow('getDeviceCatalogue'),
    },
    {
      name: t('featureManagement'),
      children: [
        {
          name: t('featureController'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <FeatureControllerIcon />,
          url: '/super-admin/feature-controller',
          active: false,
          key: 'feature',
          enable: checkPermissionAllow('getFeatureController'),
        },
      ],
      enable: checkPermissionAllow('getFeatureController'),
    },
    {
      name: t('playerAppManagement'),
      children: [
        {
          name: t('playerAppUpload'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <FeatureControllerIcon />,
          url: '/super-admin/player-app-upload',
          active: false,
          key: 'player',
          enable: checkPermissionAllow('getPlayAppManager'),
        },
      ],
      enable: checkPermissionAllow('getPlayAppManager'),
    },
    {
      name: t('websiteLeads'),
      children: [
        {
          name: t('contacts'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <FeatureControllerIcon />,
          url: '/super-admin/contacts',
          active: false,
          key: 'feature',
          enable: checkPermissionAllow('getContacts'),
        },
      ],
      enable: checkPermissionAllow('getContacts'),
    },
    {
      name: t('licenseManagement'),
      children: [
        {
          name: t('licenses'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <DeviceOrderIcon />,
          url: '/super-admin/licenses',
          active: false,
          key: 'catalogue',
          enable: checkPermissionAllow('getDeviceRental'),
        },
        {
          name: t('transactions'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <TransactionIcon />,
          url: '/super-admin/license-transaction/summary',
          active: false,
          key: 'catalogue',
          enable: checkPermissionAllow('getDeviceRental'),
        },
        {
          name: t('plansAndPricing'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <PlanIcon />,
          url: '/super-admin/licenseplan',
          active: false,
          key: 'catalogue',
          enable: checkPermissionAllow('getDeviceCatalogue'),
        },
        {
          name: t('licenseFeatureController'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <FeatureControllerIcon />,
          url: '/super-admin/license-featureController',
          active: false,
          key: 'catalogue',
          enable: checkPermissionAllow('getFeatureController'),
        },
      ],
      enable: checkPermissionAllow('getDeviceRental')
        || checkPermissionAllow('getFeatureController')
        || checkPermissionAllow('getDeviceCatalogue'),
    },
  ];

  const [menus, setMenus] = useState([]);

  const changeActive = () => {
    const menu = sidebar.map((e) => {
      e.children = e.children.map((i) => ({ ...i, active: i.url.includes(slicedString) && true }));
      return e;
    });
    setMenus(menu);
  };

  useEffect(() => {
    changeActive();
  }, [navigate]);

  const toggleDrawer = () => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(false);
  };
  return (
    <div className="side-bar">
      <div>
        <div className="logo-image">
          <Logo />
        </div>
        <div className="sidebar-menu">
          {
            menus.map((e, index) => e.enable && <Menu data={e} click={changeActive} index={`menu_${index}`} />)
          }
        </div>
      </div>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className="sidebar-drawer"
      >
        <div className="drawer-content">
          <div className="drawer-header">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon color="#000" />
            </IconButton>
          </div>
          <div className="drawer-menu">
            {
              menus.map((menu) => (
                menu.enable && (
                  <div className="sidebar-menu-session">
                    <label className="menu-header" htmlFor={menu.name}>{menu.name}</label>
                    <ul className="menu-list">
                      {
                        menu.children.map((subMenu) => (
                          subMenu.enable && (
                            <li
                              className={subMenu.active ? subMenu.activeClass : subMenu.class}
                              onClick={() => {
                                navigate(subMenu.url);
                                setDrawerOpen(false);
                              }}
                              role="presentation"
                            >
                              <div className="sub-menu">
                                {subMenu.svgIcon && (
                                  <span>
                                    {
                                      React.cloneElement(
                                        subMenu.svgIcon,
                                        { color: subMenu.active ? '#1C75BC' : '#8E8E8E' },
                                      )
                                    }
                                  </span>
                                )}
                                <span>{subMenu.name}</span>
                              </div>
                            </li>
                          )
                        ))
                      }
                    </ul>
                  </div>
                )
              ))
            }
          </div>
        </div>
      </Drawer>
    </div>
  );
}

function Menu(props) {
  return (
    <div className="sidebar-menu-session" key={`menus_${props?.index}`}>
      <label className="menu-header" htmlFor={props?.data?.name}>{props?.data?.name}</label>
      <ul className="menu-list">
        {
          props?.data?.children.map((e) => e.enable && <SubMenu data={e} click={props?.click} />)
        }

      </ul>
    </div>
  );
}

function SubMenu(props) {
  return (
    <li
      role="presentation"
      key={props?.data?.key}
      className={props?.data?.active ? props?.data?.activeClass : props?.data?.class}
      onClick={props?.click}
    >
      <Link to={props?.data?.url}>
        <div className="sub-menu">
          <span>
            {props?.data?.svgIcon
              && (
                <span>
                  {React.cloneElement(
                    props?.data?.svgIcon,
                    { color: props?.data?.active ? '#1C75BC' : '#8E8E8E' },
                  )}
                </span>
              )}
          </span>
          <span>
            {props?.data?.name}
          </span>
        </div>
      </Link>
    </li>
  );
}
SideBar.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
};

Menu.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        activeClass: PropTypes.string.isRequired,
        class: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        activeIcon: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  click: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

SubMenu.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string.isRequired,
    activeClass: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    activeIcon: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    svgIcon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  click: PropTypes.func.isRequired,
};

export default SideBar;
